import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input, InputPassword } from '@/components/ui/input'
import { useTrans } from '@/hooks/useTranslation'
import { FormInstanceProps } from '@/types'

import { DB_CONNECT_TYPE } from '../updateConnection.constant'

const DatabaseSQL = ({
  form,
  connectType,
}: {
  form: FormInstanceProps
  connectType: string
}) => {
  const { t } = useTrans()

  return (
    <div className="flex flex-col gap-4">
      <FormField
        control={form.control}
        name="host"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="font-semibold" requiredField>
              {t('host')}
            </FormLabel>
            <FormControl>
              <Input {...field} placeholder={t('placeholderHost')} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="port"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="font-semibold" requiredField>
              {t('port')}
            </FormLabel>
            <FormControl>
              <Input {...field} placeholder={t('placeholderPort')} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="dbName"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="font-semibold" requiredField>
              {connectType === DB_CONNECT_TYPE.ORACLE
                ? t('serviceName')
                : t('dbName')}
            </FormLabel>
            <FormControl>
              <Input
                {...field}
                placeholder={
                  connectType === DB_CONNECT_TYPE.ORACLE
                    ? t('placeholderServiceName')
                    : t('placeholderDBName')
                }
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="user"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="font-semibold" requiredField>
              {t('user')}
            </FormLabel>
            <FormControl>
              <Input
                {...field}
                autoComplete="off"
                placeholder={t('placeholderUser')}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="pwd"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="font-semibold" requiredField>
              {t('password')}
            </FormLabel>
            <FormControl>
              <InputPassword
                {...field}
                autoComplete="off"
                placeholder={t('placeholderPassword')}
                type="password"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  )
}

export default DatabaseSQL
