import { FormControl, FormLabel } from '@/components/ui/form'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { useTrans } from '@/hooks/useTranslation'

import DatasetCreateLayout from '../datasetCreateLayout/datasetCreateLayout'
import BigQuery from './bigQuery/bigQuery'
import DatabaseSQL from './databaseSQL/databaseSQL'
import { DB_CONNECT_TYPE } from './updateConnection.constant'
import {
  UpdateConnectionProvider,
  useUpdateConnectionContext,
} from './updateConnection.context'
import { useUpdateConnection } from './updateConnection.hook'
import { UpdateConnectionProps } from './updateConnection.props'

const UpdateConnection = (props: UpdateConnectionProps) => {
  const { t } = useTrans()
  const { form, currentConnectType, handleChangeConnectionType } =
    useUpdateConnection(props)

  return (
    <div className="w-full">
      {/* <label className="flex items-center gap-2 base1 font-medium cursor-pointer">
        <Checkbox />
        Use SHH Tunnel
      </label> */}

      <div className="w-full p-6 border border-slate-200 rounded-md mt-8 flex flex-col gap-4">
        <FormLabel className="caption2" requiredField>
          {t('service')}
        </FormLabel>
        <Select
          onValueChange={handleChangeConnectionType}
          value={currentConnectType}
        >
          <FormControl>
            <SelectTrigger>
              <SelectValue placeholder="Please select a type of database" />
            </SelectTrigger>
          </FormControl>
          <SelectContent>
            <SelectItem value={DB_CONNECT_TYPE.MYSQL}>MySQL</SelectItem>
            <SelectItem value={DB_CONNECT_TYPE.POSTGRES_SQL}>
              PostgreSQL
            </SelectItem>
            <SelectItem value={DB_CONNECT_TYPE.ORACLE}>Oracle DB</SelectItem>
            <SelectItem value={DB_CONNECT_TYPE.BIG_QUERY}>Big-query</SelectItem>
          </SelectContent>
        </Select>
        {currentConnectType === DB_CONNECT_TYPE.BIG_QUERY ? (
          <BigQuery form={form} />
        ) : (
          <DatabaseSQL connectType={currentConnectType} form={form} />
        )}
      </div>
    </div>
  )
}

const UpdateConnectionWithLayout = () => {
  const { t } = useTrans()
  const { validationSchema } = useUpdateConnectionContext()
  return (
    <DatasetCreateLayout
      schema={validationSchema}
      title={t('titleUpdateConnect')}
    >
      {(props) => <UpdateConnection {...props} />}
    </DatasetCreateLayout>
  )
}

const UpdateConnectionContainer = () => {
  return (
    <UpdateConnectionProvider>
      <UpdateConnectionWithLayout />
    </UpdateConnectionProvider>
  )
}

export default UpdateConnectionContainer
